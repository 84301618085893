import React from "react";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";
import { SEO } from "../components";

const intro = `At The Ear Place not only do we diagnose and treat hearing loss but we can also assist in preventing or delaying the onset of hearing loss in the first place or making certain activities more comfortable and enjoyable.`;

const HearingProtection = () => {
  return (
    <>
      <SEO title="Hearing Protection & Noise Reduction" />
      <BlankPageNoImage
        header="HEARING PROTECTION / NOISE REDUCTION"
        intro={intro}
        ariaLabel="HEARING PROTECTION / NOISE REDUCTION"
      >
        <div>
          <div tw="md:flex pb-12">
            <StaticImage
              src="../images/hearing-protection-diagram.png"
              alt="Permissible exposure time"
              tw="md:flex-basis[50%]"
            />
            <StaticImage
              src="../images/hearing-protection-diagram2.png"
              alt="Pain thresholds"
              tw="md:flex-basis[50%]"
            />
          </div>
          <ProseWrapper>
            <h4>Hearing Protection &amp; Noise Reduction</h4>
            <p>
              There are 2 factors which influence how much of a damaging effect
              sound can have on the ears:
              <br />
              <br />- How loud the sound is
              <br />- How long the exposure to the sound is
              <br />
              <br />
              Loud sounds that happen very suddenly are capable of damaging the
              ear and hearing both permanently and instantly due to the level of
              the noise produced. However, being in a lower level sound for a
              longer period of time can be equally as damaging as the ear is not
              given time to recover which can result in permanent damage.
            </p>

            <p>
              Hearing protection is available for a wide range of hobbies,
              activities or careers such as:
            </p>
            <p>
              - Shooting
              <br />- Motorsports
              <br />- General noise
              <br />- Musicians
              <br />- Radio/tv presenters
              <br />- In-ear monitors
              <br />- Headphones
            </p>
            <p>
              We also offer other types of ear protection such as swimming plugs
              for individuals who suffer from pain, irritation or infection from
              getting water in their ears.
            </p>
            <p>
              All of our hearing protection or noise reduction products will be
              custom made to ensure maximum protection and comfort, they are
              available in a range of colours and come with free aftercare to
              ensure satisfaction.
            </p>
          </ProseWrapper>
        </div>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default HearingProtection;
